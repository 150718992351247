import { Box, Container, Typography } from "@mui/material";
const EParking = () => {
    return (
        <>
            <Container maxWidth="lg" align="center" className="eParkingSection" id="eparking solutionSection">
                {/* <Box className="imgSec" id="eparkingSection">
                    <img src="/icons/smarteparking.png" />
                </Box> */}
                    <Typography variant="h3" gutterBottom >
                        Smart E-Parking Solution
                    </Typography>
                    {/* <Box className="imgSec" id="eparkingSection">
                        <img src="/icons/smarteparking.png" />
                    </Box> */}
                <Box className="EParkingContent">
                    <Typography variant="subtitle1" gutterBottom >
                        E-parking solutions are revolutionizing the way we park our vehicles in today’s modern world. These solutions use advanced technologies, such as sensors, cameras, and cloud-based software, to streamline the parking process and make it more efficient and convenient for drivers. It consist of Mobile App , Backend and Admin Portal
                    </Typography>
                    <Box className="eParkingList">
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="one" >
                                Smart e-parking solutions, drivers can easily locate available parking spaces using real-time data and mapping features. They can also reserve and pay for parking spots in advance, reducing the need to circle around looking for a parking spot or wait in long lines to pay for parking.
                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="two" >
                                E-parking solutions are also beneficial for parking facility owners and operators. By using advanced data analytics and reporting tools, they can optimize their parking operations and maximize their revenue. They can also reduce operational costs, as automated payment and reservation systems require less staff to manage.
                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="three" >
                                Addition, smart e-parking solutions can improve overall traffic flow and reduce congestion in some areas. With fewer drivers circling around looking for parking, traffic can move more smoothly, leading to reduced carbon emissions and a cleaner environment.
                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="four" >
                                Smart e-parking solutions are transforming the parking industry, making it more efficient, convenient, and sustainable for both drivers and parking facility owners. With continued innovation and advancements in technology, we can expect to see even more exciting developments in the world of smart e-parking solutions in the future.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    )
}
export default EParking;