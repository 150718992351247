import { Box, Container, Typography } from "@mui/material";
const PrivacyPolicy = () => {
    return (
        <>
            <Container maxWidth="lg" align="center" className="eParkingSection privacypolicySection" id="privacypolicySection">
                {/* <Box className="imgSec" id="eparkingSection">
                    <img src="/icons/smarteparking.png" />
                </Box> */}
                <Typography variant="h3" gutterBottom >
                    Privacy Policy 
                </Typography>
                {/* <Box className="imgSec" id="eparkingSection">
                        <img src="/icons/smarteparking.png" />
                    </Box> */}
                <Box className="EParkingContent">
                    <Typography variant="subtitle1" gutterBottom >
                    This privacy policy clarifies how personal data is processed, as the directory application website is committed to maintaining the confidentiality of sensitive data.

                     -This policy has been prepared to disclose the application policy in data collection and dissemination. 
                     </Typography>
                    <Box className="eParkingList">
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="one" >
                            Collection of Personal Information:<br></br>
                            a. Tarqia will periodically receive and store personal information that you provide via our application, whether directly or in other forms.<br></br>
                            b. Basic details such as your name, phone number, address, and email may be collected to send information, updates, and process orders. Additional information may be collected during feedback submission, change of content or email preferences, survey responses, financial data, or communication with customer support.<br></br>
                            c. Any other information you provide during interactions with us may also be collected.
                             </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="two" >
                            How We Collect Personal Information:<br></br>
                            a. Tarqia collects personal information through electronic and in-person interactions, application usage, and service provision. Information from third parties may also be received and will be protected as per this Privacy Policy.
                             </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="three" >
                            Use of Personal Information:<br></br>
                            a. Personal information collected may be used to provide information, updates, and services. You may also be informed of new products, services, and opportunities. The information helps improve our offerings and understand your needs.<br></br>
                            b. Third-party social media features may be offered through the application. The security of publicly shared social media information cannot be guaranteed. We may contact you via various channels, including phone, email, SMS, or mail.              
                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="four" >
                            Disclosure of Personal Information:<br></br>
                            a. Personal information may be disclosed to employees, officers, advisers, agents, or suppliers for the outlined purposes. Disclosure only occurs when necessary for service provision.<br></br>
                            b. Legal requirements, such as court orders, legal proceedings, or law enforcement requests, may necessitate personal information disclosure.<br></br>
                            c. Personal information may be used to safeguard copyright, trademarks, legal rights, or the safety of Tarqia, its application, website, customers, or third parties.<br></br>
                            d. Information collected may be stored or processed in countries outside Australia.<br></br>
                            e. During changes in business control, assets, or sales, user databases and personal information may be transferred under a confidentiality agreement.                        
                             </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="five" >
                            Security of Personal Information:<br></br>
                            a. Tarqia ensures information security through physical, electronic, and managerial measures to protect against unauthorized access, disclosure, and misuse.<br></br>
                            b. While measures are taken to protect information, transmission and exchange of data are at your risk. Security of transmitted information cannot be guaranteed.
                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="six" >
                            Complaints about Privacy:<br></br>
                            a. Complaints can be submitted to our address. We take complaints seriously and respond promptly.

                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="seven" >
                            Changes to Privacy Policy:<br></br>
                            a. We reserve the right to modify this Privacy Policy. Changes will be effective immediately upon posting.


                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="eight" >
                            Software Application:<br></br>
                            a. Automatic collection:When using the application, certain information is collected. Cookies and automatic collection mechanisms are also utilized.
                            b.  Third parties: The application may link to third-party websites; Tarqia is not responsible for their privacy practices.

                            c.Geo-Location: The application may use GPS technology to determine your location. Your location data will not be shared with other users or partners.
                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="nine" >
                            Account Deletion<br></br>
                            We value your privacy and believe in giving you full control over your personal information. If you no longer wish to use our Smart Digital Parking App and want to delete your account, we provide a straightforward process for you to do so.<br></br>

                            To delete your account and all associated information, please follow these steps:<br></br>

                           1- Open the Digital Parking Application:
                            Launch the Smart Digital Parking App on your device.<br></br>

                            2-Log in (if not already logged in):
                             Enter your login credentials to access your account.<br></br>

                            3- Navigate to the Settings Tab:
                            Locate the Settings tab within the app's menu or navigation options.<br></br>

                            4-Find the Delete Account Option:
                            Within the Settings tab, you will find the option to delete your account.<br></br>

                            5-Confirm Account Deletion:
                            When you select the delete option, the app will prompt you to enter your password for confirmation.<br></br>

                             6- Permanently Delete Your Account:
                            Once you confirm the deletion by entering your password, your account and all associated information will be permanently deleted from our system.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    )
}
export default PrivacyPolicy;