import { Container, Typography } from "@mui/material";
import VerticalTabs from '../../component/location/tabsCompo'
const Location = () => {
    return (
        <>
            <Container maxWidth="lg" align="center" className="locationSection" id="ourlocationSection">
                <Typography variant="h3" gutterBottom >
                    Our Location
                </Typography>
                <Typography variant="subtitle1" gutterBottom >
                    It is a long established fact that a reader will be distracted by the readable content of a <br /> page when looking at its layout
                </Typography>
            </Container>
            <VerticalTabs/>
        </>
    )
}
export default Location;