import { Container } from "@mui/system";
import SimpleSlider from './../carousel/slideComp'

const Carousel = (navStatus) => {
    return (
        <>
            <Container className="simpleSliderComp" id="homeSection"> 
                <SimpleSlider />
            </Container>
        </>
    )
}
export default Carousel;


