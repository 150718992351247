import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }} className="locationTabPills"
        >
            <Tabs
                orientation="vertical"
                className="orientationTabPills"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                {/* <Tab label="JeddahIndustrial City, Phase 5 Jeddah, Saudi Arabia" {...a11yProps(0)} className="tabBtn" /> */}
                <Tab label="6th of October – Giza – Egypt" {...a11yProps(1)} className="tabBtn" />
                {/* <Tab label="JeddahIndustrial City, Phase 5 Jeddah, Saudi Arabia" {...a11yProps(2)} className="tabBtn" />
                <Tab label="JeddahIndustrial City, Phase 5 Jeddah, Saudi Arabia" {...a11yProps(3)} className="tabBtn" />
                <Tab label="JeddahIndustrial City, Phase 5 Jeddah, Saudi Arabia" {...a11yProps(4)} className="tabBtn" /> */}
            </Tabs>
            <TabPanel value={value} index={0} className="rightSideData">
                <img src="/icons/imagelocation.png" style={{width:'100%'}}  />
            </TabPanel>
            {/* <TabPanel value={value} index={1} className="rightSideData">
                <img src="/icons/mapimage1.png" style={{width:'100%'}} />
            </TabPanel> */}
            {/* <TabPanel value={value} index={2} className="rightSideData">
                <img src="/icons/mapimage.png" />
            </TabPanel>
            <TabPanel value={value} index={3} className="rightSideData">
                <img src="/icons/mapimage.png" />
            </TabPanel>
            <TabPanel value={value} index={4} className="rightSideData">
                <img src="/icons/mapimage.png" />
            </TabPanel> */}
        </Box>
    );
}