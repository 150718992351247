import "./App.css";
import Location from "../src/component/location";
import Footer from "../src/component/footer";
import AboutUs from "../src/component/aboutUs";
import Parking from "../src/component/parkingSection";
import Carousel from "../src/component/carousel";
import TopHeader from "../src/component/topHeader";
import EParking from "../src/component/eParkingSolution";
import Services from "../src/component/services";
import { Box } from "@mui/system";
import PrivacyPolicy from "./component/privacyPolicy";
const App = () => {
  return (
    <>
      <Box className="headerCarouselSection">
        <TopHeader />
        <Carousel />
      </Box>
      <EParking />
      <Parking />
      <Services />
      <AboutUs />
      <PrivacyPolicy />
      <Location />
      {/* <Customer /> */}
      <Footer />
    </>
  );
};
export default App;
