import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography } from '@mui/material';


const Steppers = () => {
  return (
    <Box sx={{ width: '100%' }} className="parkingStepperSection">
      <Box className="leftStripper">
        <Box className="stepperIcon">
          {/* <img src="" alt="img1" /> */}
        </Box>
        <Typography variant="h3">
          Wherever, Whenever
        </Typography>
        <Typography>
          Lorem Lpsum is simply dummy text of the printing and <br/>typesting industry.
        </Typography>
      </Box>
      <Box className="middleStripper">
        <Box className="stepperIcon">
          {/* <img src="" alt="img1" /> */}
        </Box>
        <Typography variant="h3">
              Quick & Easy Parking
        </Typography>
        <Typography>
          Lorem Lpsum is simply dummy text of the printing and <br/>typesting industry.
        </Typography>
      </Box>
      <Box className="rightStripper">
        <Box className="stepperIcon">
          {/* <img src="" alt="img1" /> */}
        </Box>
        <Typography variant="h3">
              Safe & Secure
        </Typography>
        <Typography>
          Lorem Lpsum is simply dummy text of the printing and <br/>typesting industry.
        </Typography>
      </Box>
      {/* <Stepper activeStep={1} alternativeLabel>
        <Step className="leftStripper">
          <StepLabel>
            <Box className="stepperIcon">
            </Box>
            <Typography variant="h3">
              Whereever, Whenever
            </Typography>
            <Typography>
              Lorem Lpsum is simply dummy text of the printing and typesting industry.
            </Typography>
          </StepLabel>
        </Step>
        <Step className="middleStripper">
          <StepLabel>
            <Box className="stepperIcon">
            </Box>
            <Typography variant="h3">
              Quick & Easy Parking
            </Typography>
            <Typography>
              Lorem Lpsum is simply dummy text of the printing and typesting industry.
            </Typography>
          </StepLabel>
        </Step>
        <Step className="rightStripper">
          <StepLabel>
            <Box className="stepperIcon">
            </Box>
            <Typography variant="h3">
              Safe & Secure
            </Typography>
            <Typography>
              Lorem Lpsum is simply dummy text of the printing and typesting industry.
            </Typography>
          </StepLabel>
        </Step>
      </Stepper> */}
    </Box>
  );
}
export default Steppers;

