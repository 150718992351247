import * as React from 'react';
import { CardMedia, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
const Footer = () => {

    const [navStatus, setNavStatus] = React.useState("Home")
    const HandleNav = (content) => {
        setNavStatus(content)
        let newContent = content?.replace(" ", "")
        const scrollToId = `${newContent?.toLowerCase()}Section`;
        document.getElementById(scrollToId)?.scrollIntoView({ behavior: "smooth" })
        console.log("scrollToId", scrollToId);
    }
    return (
        <>
            <Box className="footerSection" id="contactusSection">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className="leftSectionFooter">
                        <CardMedia
                            style={{
                                width: "auto",
                                maxHeight: "200px",
                                marginTop: '30px'
                            }}
                            component="img"
                            image="icons/logobottom.svg"
                            alt="green iguana"
                            className="footerleftIcon"
                        />
                        <Box className="socialSection">
                            <Box sx={{ display: 'flex', m: 'auto', justifyContent: 'center' }} className="rightInnerSection">
                                <CardMedia
                                    style={{
                                        width: "50px",
                                        height: '50px',
                                        marginTop: '30px',
                                        marginRight: '10px'
                                    }}
                                    component="img"
                                    image="icons/fbicon.svg"
                                    alt="green iguana"
                                />
                                <CardMedia
                                    style={{
                                        width: "50px",
                                        height: '50px',
                                        marginTop: '30px'
                                    }}
                                    component="img"
                                    image="icons/linkedin.svg"
                                    alt="green iguana"
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} lg={6} className="middleSectionFooter">
                        <Box className="footerNav">
                            <Typography onClick={() => { HandleNav("Home") }}>Home</Typography>
                            <Typography onClick={() => { HandleNav("E Parking") }}>E Parking Solution</Typography>
                            <Typography onClick={() => { HandleNav("About Us") }}>About Us</Typography>
                            <Typography onClick={() => { HandleNav("Our Location") }}>Our Location</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={4} className="rightSectionFooter">
                        <Typography variant="h3">Contact Us</Typography>
                        <Typography className="address">6th of October – Giza – Egypt</Typography>
                        <Typography className="telEmail"><img src="/icons/address.svg" /> www.tarqia.net</Typography>
                        <Typography className="telEmail"><img src="/icons/tel.svg" /> info@tarqia.net</Typography>
                        <Typography className="telEmail"><img src="/icons/mail.svg" /> +20 1221667895</Typography>
                    </Grid>
                    <Typography className="allRightReserve">
                        &copy; 2023 Tarqia All right reserved
                    </Typography>
                </Grid>
            </Box>
        </>
    )
}
export default Footer;