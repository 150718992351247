import * as React from 'react';
import { Box, Button, Container, List, ListItem, Typography } from "@mui/material";

const AboutUs = () => {
    // const [navStatus, setNavStatus] = React.useState("readMore")
    // const HandleNav = (content) => {
    //     setNavStatus(content)
    //     let newContent = content?.replace(" ", "")
    //     const scrollToId = `${newContent?.toLowerCase()}Section`;
    //     document.getElementById(scrollToId)?.scrollIntoView({ behavior: "smooth" })
    //     console.log("scrollToId", scrollToId);
    // }
    return (
        <>
            <Container className="aboutSection" id="aboutusSection">
                <Box className="aboutImg">
                    <img src="/icons/aboutusimage.png" />
                </Box>
                <Box className="aboutContent">
                    <Typography variant="h4" className="aboutHeading">
                        About Us
                    </Typography>

                    <Typography className="contentParagraph">
                        TARQIA is a professional services solution provider that offers a wide range of services to businesses
                        of all sizes. As an industry leader, TARQIA is dedicated to delivering high-quality products and services
                        to its clients, with the ultimate goal of helping businesses achieve success.
                    </Typography>
                    <Typography className="contentParagraph">
                        At TARQIA, the focus is on providing comprehensive solutions that meet the specific needs of each client. Whether its consulting services,
                        project management, software development, or any other professional service, the team at TARQIA has the expertise and experience to deliver
                        exceptional results.
                        {/* <Typography variant="span" onClick={() => { HandleNav("readMore") }}>READ MORE...</Typography> */}
                    </Typography>
                    {/* <Box sx={{ my: '80px' }} id="readmoreSection"> */}
                        <Typography className="contentParagraph">
                            TARQIA is committed to delivering 100% quality products and services. This means that every project undertaken is approached with a rigorous quality assurance process to ensure that the final product meets or exceeds client expectations. The team at TARQIA takes pride in their work and strives to deliver the best possible outcome for their clients.
                        </Typography>
                        <Typography className="contentParagraph">
                            The ultimate goal of TARQIA is to help businesses achieve success. Whether it’s by developing innovative solutions, improving operational efficiency, or enhancing customer experience, TARQIA is dedicated to helping businesses achieve their objectives. The team at TARQIA understands that every business is unique, and as such, they take a customized approach to each project, tailoring solutions to meet the specific needs of each client.
                        </Typography>
                        <Typography className="contentParagraph">
                            Overall, TARQIA is an industry-leading professional services solution provider that is committed to delivering high-quality products and services. With a focus on quality, expertise, and customization, TARQIA is dedicated to helping businesses achieve success and reach their ultimate goals.
                        </Typography>
                    {/* </Box> */}
                </Box>
            </Container>
        </>
    )
}
export default AboutUs;