import { Box, Typography } from "@mui/material";
import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      // autoplay: true,
      // speed: 2000,
      // autoplaySpeed: 3000,
      slidesToScroll: 1
    };
    return (
      <div >
        <Slider {...settings}>
          {/* <Box sx={{ width: '100%' }} className="sliderContainer">
            <img src="/icons/firstsecimage.png" sx={{ width: '100%' }} />
            <Box className="contentBox">
              <Typography variant="h2">
                Find Amazing Parking Space <br />Near You
              </Typography>
            </Box>
          </Box> */}
          {/* <Box sx={{ width: '100%' }} className="sliderContainer">
            <img src="/icons/firstsecimage.png" sx={{ width: '100%' }} />
            <Box className="contentBox">
              <Typography variant="h2">
                Find Amazing Parking Space <br />Near You
              </Typography>
            </Box>
          </Box> */}
          <Box sx={{ width: '100%' }} className="sliderContainer">
            <img src="/icons/img4.png" sx={{ width: '100%' }} />
            <Box className="contentBox">
              <Typography variant="h2">
                Find Amazing Parking Space <br />Near You
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '100%' }} className="sliderContainer">
            <img src="/icons/img3.png" sx={{ width: '100%' }} />
            <Box className="contentBox">
              <Typography variant="h2">
                Find Amazing Parking Space <br />Near You
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '100%' }} className="sliderContainer">
            <img src="/icons/img2.png" sx={{ width: '100%' }} />
            <Box className="contentBox">
              <Typography variant="h2">
                Find Amazing Parking Space <br />Near You
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '100%' }} className="sliderContainer">
            <img src="/icons/img1.png" sx={{ width: '100%' }} />
            <Box className="contentBox">
              <Typography variant="h2">
                Find Amazing Parking Space <br />Near You
              </Typography>
            </Box>
          </Box>
        </Slider>
      </div>
    );
  }
}