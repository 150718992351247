import { Container, Typography } from "@mui/material";
import Steppers from '../../component/parkingSection/StepperComp';
const Parking = () => {
    return (
        <>
            <Container maxWidth="lg" align="center" className="parkingSection">
                <Typography variant="h3" gutterBottom >
                    Parking Made Easy
                </Typography>
                <Typography variant="subtitle1" gutterBottom >
                    It is a long established fact that a reader will be distracted by the readable content <br /> of a page when looking at its layout
                </Typography>
            </Container>
            <Steppers/>
        </>
    )
}
export default Parking;