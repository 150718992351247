import { Box, Container, Typography } from "@mui/material";
const Services = () => {
    return (
        <>
            <Container maxWidth="lg" align="center" className="eParkingSection" id="eparking solutionSection">
                {/* <Box className="imgSec" id="eparkingSection">
                    <img src="/icons/smarteparking.png" />
                </Box> */}
                <Typography variant="h3" gutterBottom >
                    Professional Services
                </Typography>
                {/* <Box className="imgSec" id="eparkingSection">
                        <img src="/icons/smarteparking.png" />
                    </Box> */}
                <Box className="EParkingContent">
                    <Typography variant="subtitle1" gutterBottom >
                        TARQIA offers a wide range of professional services to help businesses succeed in today’s fast-paced and constantly evolving digital landscape. Here are some of the core services that TARQIA provides:                    </Typography>
                    <Box className="eParkingList">
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="one" >
                                Change Management and Enterprise Development - TARQIA helps companies manage change and develop their enterprises through effective strategies, processes, and tools.                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="two" >
                                Software Development - TARQIA has a team of skilled software developers who can design and develop custom software solutions that meet the unique needs of their clients.                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="three" >
                                Project Management Experts - TARQIA’s project management experts can help businesses manage their projects from start to finish, ensuring that they are completed on time, within budget, and to the highest standards of quality.                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="four" >
                                Business and technical transformation - TARQIA helps businesses transform their digital and technical capabilities, helping them adapt to the constantly evolving landscape of the digital world.                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="five" >
                                System Integration - TARQIA helps businesses integrate their systems and technologies, ensuring that they work together seamlessly to achieve their business objectives.
                            </Typography>
                        </Box>
                        <Box className="listContainer">
                            <Typography variant="subtitle1" className="six" >
                                Overall, TARQIA’s wide range of professional services is designed to help businesses succeed in today’s digital landscape, by providing them with the expertise, tools, and strategies they need to thrive.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    )
}
export default Services;