import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AccountMenu from '../../component/topHeader/language'
import { useState } from 'react';

const navItems = ['Home', 'E Parking Solution','About Us','Privacy Policy', 'Our Location','Contact Us',];

const TopHeader = (props) => {

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const [navStatus,setNavStatus] = React.useState("Home")

    const HandleNav = (content) => {
        setNavStatus(content)
        let newContent = content?.replace(" ","")
        const scrollToId = `${newContent?.toLowerCase()}Section`;
        document.getElementById(scrollToId)?.scrollIntoView({behavior:"smooth"})
        console.log("scrollToId", scrollToId);
    }
    // const handleClickNav = () => {
    // }
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                <img src="/icons/logoup.svg" />
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }} className="smallDevice">
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        <img src="/icons/logoup.svg" className="logoImg" alt="logo" />
                    </Typography>
                    <Box className="headerMenuButton">
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }} >
                            {navItems.map((item) => (
                                <Button className={navStatus == item ? "active" :""} onClick={() => { HandleNav(item) }} key={item}>
                                    {item}
                                </Button>
                            ))}
                        </Box>
                        {/* <Typography>
                            aman
                        </Typography> */}
                        <AccountMenu />
                    </Box>

                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

TopHeader.propTypes = {
    window: PropTypes.func,
};

export default TopHeader